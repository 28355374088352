import * as React from 'react'
import { Link } from 'gatsby'
import "@fontsource/vidaloka"; // Defaults to weight 400.
import "@fontsource/source-sans-pro"; // Defaults to weight 400.
import { container } from './layout.module.css'
import { siteheader } from './siteheader.module.css'

const Layout = ({ pageTitle, children }) => {
  return (
	  <main className={container}>
		  <title>{pageTitle}</title>
		  <header className={siteheader}>
		  <h1>{pageTitle}</h1>
			  <h2>Cask and Tap House</h2>
			  </header>
      {children}
    </main>
  )
}
export default Layout