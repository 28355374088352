// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout'

// Step 2: Define your component
const IndexPage = () => {
  return (
    <Layout pageTitle="Beer Pharmacie">

<section>
        <h2>FIND US</h2>
        <p>3 High St, Syston, Leicester, LE7 1GP</p>
      </section>
      <section>
        <h2>OPENING HOURS</h2>
        <p>Monday and Tuesday: CLOSED</p>
        <p>Wednesday - Sunday: 4pm to 11pm</p>
      </section>
      <section>
        <iframe src="https://business.untappd.com/embeds/iframes/22062/84073" frameborder="0" height="2000" width="100%"></iframe>
      </section>
</Layout>
  )
}
// Step 3: Export your component
export default IndexPage